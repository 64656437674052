export const defaultMapping = { reference_number: '', notes: '' };
export const onholdMapping = { reference_number: '', notes: '' };
export const keyMappingPaymentCleared = {
  reference_number: '',
  notes: '',
};
export const inscanAtHubMapping = {
  reference_number: '',
  hub_code: '',
  notes: '',
};

export const markArrivalAtHubMapping = {
  reference_number: '',
  hub_code: '',
};

export const markPickupCompletedMapping = {
  reference_number: '',
  notes: '',
};

export const markNotPickedUpMapping = {
  reference_number: '',
  notes: '',
};

export const markEfsToPushPodMapping = {
  trip_number: '',
  trip_type: '',
  notes: '',
};

export const markUndeliveredMapping = {
  reference_number: '',
  failure_reason_id: '',
  attempted_timestamp: '',
  notes: '',
};
export const editDutyChargesMapping = {
  reference_number: '',
  duty_charges: '',
  notes: '',
};
export const updateCODAmountMapping = {
  reference_number: '',
  cod_amount: '',
  notes: '',
};
export const markDeliveredMapping = {
  reference_number: '',
  delivery_timestamp: '',
  cod_amount_collected: '',
  notes: '',
};
export const updateAddressMapping = {
  reference_number: '',
  src_name: '',
  src_address_line_1: '',
  src_address_line_2: '',
  src_address_line_3: '',
  src_city: '',
  src_state: '',
  src_country: '',
  src_pincode: '',
  src_phone: '',
  src_alternate_phone: '',
  src_area: '',
  dst_name: '',
  dst_address_line_1: '',
  dst_address_line_2: '',
  dst_address_line_3: '',
  dst_city: '',
  dst_state: '',
  dst_country: '',
  dst_pincode: '',
  dst_phone: '',
  dst_alternate_phone: '',
  dst_area: '',
  src_zone: '',
  dst_zone: '',
};

export const transporterIDsUpdateMapping = {
  reference_number: '',
  transporter_id: '',
  notes: '',
};

export const applyExceptionsUpdateMapping = {
  reference_number: '',
  exception_code: '',
  notes: '',
};

export const releaseExceptionsUpdateMapping = {
  reference_number: '',
  notes: '',
  exception_code: '',
};

export const applyPieceLevelExceptionUpdateMapping = {
  reference_number: '',
  exception_code: '',
  notes: '',
};

export const consignorConsigneeUpdateMapping = {
  reference_number: '',
  consignor_code: '',
  consignee_code: '',
};

export const consignmentEventNotesUpdateMapping = {
  reference_number: '',
  event_type: '',
  notes: '',
};

export const updateEWBMapping = {
  reference_number: '',
  invoice_number: '',
  ewb_number: '',
  invoice_value: '',
  identifier: '',
  notes: '',
};

export const reduceHuMapping = {
  reference_number: 'Parent Reference Number',
  hu_number: 'HU Number',
};

export const retryBookingMapping = {
  reference_number: '',
  notes: '',
};

export const triggerOutboundEventMapping = {
  reference_number: '',
  event_type: '',
  notes: '',
};

export const pickupCompletedMapping = {
  reference_number: '',
  notes: '',
};

export const pickupRejectedMapping = {
  reference_number: '',
  notes: '',
};

export const updateTaskStatusMapping = {
  reference_number: '',
  task_type: '',
};

export const revokeCourierHandoverMapping = {
  reference_number: '',
};

export const bulkRescheduleMapping = {
  reference_number: '',
  reschedule_date: '',
  delivery_timeslot: '',
  reschedule_reason: '',
};

export const getUpdateActions = (masterData) => {
  return [
    {
      key: 'mark_rto',
      label: 'Set RTO',
      show: masterData.show_set_rto_option,
    },
    {
      key: 'revoke_rto',
      label: 'Revoke RTO',
      show: masterData.show_set_revoke_rto_option,
    },
    {
      key: 'bulk_mark_pickup_completed',
      label: 'Mark Pickup Completed',
      show: masterData.show_pickup_completed_option,
    },
    {
      key: 'bulk_mark_not_picked_up',
      label: 'Mark Not Picked Up',
      show: masterData.show_not_picked_up_option,
    },
    {
      key: 'show_efs_to_push_pod_option',
      label: 'Fr8 First Integrations',
      isAllowed: masterData.show_efs_push_pod_option,
    },
    {
      key: 'mark_delivered',
      label: 'Mark Delivered',
      show: masterData.show_mark_delivered,
    },
    {
      key: 'mark_undelivered',
      label: 'Mark Undelivered',
      show: masterData.show_mark_delivered,
    },
    {
      key: 'mark_cancel',
      label: 'Set Cancel',
      show: masterData.show_set_cancel_option,
    },
    {
      key: 'revoke_cancel',
      label: 'Revoke Cancel',
      show: masterData.show_revoke_cancel_option,
    },
    {
      key: 'mark_onhold',
      label: 'Set Onhold',
      show: masterData.show_set_onhold_option,
    },
    {
      key: 'mark_consignment_intransit_to_hub',
      label: 'In Transit To Hub',
      show: masterData.show_mark_intransit_to_hub_update,
    },
    {
      key: 'mark_release_onhold',
      label: 'Release Onhold',
      show: masterData.show_release_onhold_option,
    },
    {
      key: 'mark_payment_cleared',
      label: 'Mark Payment Cleared',
      show: masterData.show_payment_clear_option,
    },
    {
      key: 'mark_inscan_at_hub',
      label: 'Mark Inscan At Hub',
      show: masterData.show_mark_inscan_at_hub_option,
    },
    {
      key: 'revert_from_not_picked_up',
      label: 'Revert Pickup Failed',
      show: masterData.show_revert_pickup_failed_option,
    },
    {
      key: 'revert_from_pickup_completed',
      label: 'Revert Pickup Completed',
      show: masterData.show_revert_pickup_completed_option,
    },
    {
      key: 'eway_bill_sync',
      label: 'Refresh Ewaybill Details',
      show: masterData.show_eway_bill_sync_option,
    },
    {
      key: 'origin_hub_sync',
      label: 'Refresh Origin Hub',
      show: masterData.show_origin_hub_sync_option,
    },
    {
      key: 'holding_and_destination_hub_sync',
      label: 'Refresh Holding and Destination Hub',
      show: masterData.show_holding_and_destination_hub_sync_option,
    },
    {
      key: 'route_next_hub_sync',
      label: 'Refresh Route Next Hub',
      show: masterData.show_route_next_hub_sync_option,
    },
    {
      key: 'update_address',
      label: 'Update Address',
      show: masterData.show_update_address_option,
    },
    {
      key: 'undo_delivery',
      label: 'Revoke Delivery',
      show: masterData.show_undo_delivery_option,
    },
    {
      key: 'ewb_transporter_update',
      label: 'Set Transporter Id',
      show: masterData.show_ewb_transporter_update_option,
    },
    {
      key: 'show_cnv_in_crm',
      label: 'CN Verification',
      show: masterData.cnv_update,
    },
    {
      key: 'edit_duty_charges',
      label: 'Edit Duty Charges',
      show: masterData?.ops_dashboard_config?.parts_to_show?.show_duty_charges,
    },
    {
      key: 'update_cod_amount',
      label: 'Bulk Update COD',
      show: masterData.show_bulk_update_cod_option,
    },
    {
      key: 'bulk_reschedule',
      label: 'Bulk Reschedule',
      show: masterData.show_bulk_reschedule_option,
    },
    {
      key: 'apply_parent_level_exceptions',
      label: 'Apply Exception',
      show: masterData?.crm_config_master?.show_apply_exception_on_consignment,
    },
    {
      key: 'release_exceptions',
      label: 'Release Exception',
      show: masterData?.crm_config_master
        ?.show_release_exception_on_consignment,
    },
    {
      key: 'bulk_update_consignor_consignee',
      label: 'Update Consignor or Consignee Code',
      show: masterData?.crm_config_master?.bulk_update_config
        ?.show_bulk_update_consignor_consignee,
    },
    {
      key: 'bulk_update_consignment_event_notes',
      label: 'Update Consignment Event Notes',
      show: masterData?.crm_config_master?.bulk_update_config
        ?.show_bulk_update_consignment_event_notes,
    },
    {
      key: 'mark_pickup_awaited',
      label: 'Mark Pickup Awaited',
      show: masterData.show_mark_pickup_awaited,
    },
    {
      key: 'bulk_trigger_integration',
      label: 'Trigger Integrations',
      show: masterData?.is_support_admin,
    },
    {
      key: 'remove_hu_from_parent',
      label: 'Remove Hu From Parent Consignment',
      show: masterData?.is_support_admin,
    },
    {
      key: 'update_ewb',
      label: 'Update EWB/Invoice',
      show: masterData?.ewb_update_permission,
    },
    {
      key: 'mark_consignment_lost',
      label: 'Mark Consignment Lost',
      show: masterData.show_consignment_lost,
    },
    {
      key: 'bulk_courier_partner_reference_number_change_manual',
      label: 'Bulk Courier Partner Reference Number Change Manual',
      show: masterData?.is_support_admin,
    },
    {
      key: 'bulk_update_pod',
      label: 'Bulk Update POD',
      show: masterData?.show_bulk_update_pod,
    },
    {
      key: 'retry_booking',
      label: 'Retry MultiLeg Carrier Booking',
      show: true,
    },
    {
      key: 'cancel_booking',
      label: 'Cancel MultiLeg Booking',
      show: true,
    },
    {
      key: 'trigger_outbound_event',
      label: 'Trigger Outbound Event',
      show: masterData?.show_trigger_outbound_event,
    },
    {
      key: 'mark_arrival_at_hub',
      label: 'Mark Arrival At Hub',
      show: true,
    },
    {
      key: 'remove_cn_from_frozen_invoice',
      label: 'Remove CN from frozen invoice',
      show: masterData.ops_dashboard_config?.parts_to_show
        ?.remove_cn_from_frozen_invoice,
    },
    {
      key: 'revoke_courier_handover',
      label: 'Revoke Courier Handover',
      show: masterData?.show_revoke_courier_option,
    },
    {
      key: 'outfordelivery',
      label: 'Out For Delivery',
      show: masterData?.show_out_for_delivery_option,
    },
    {
      key: 'out_for_pickup',
      label: 'Out For Pickup',
      show: masterData?.show_out_for_pickup_option,
    },
    {
      key: 'third_party_rto',
      label: 'Carrier RTO',
      show: masterData?.show_thirdparty_set_rto_option,
    },
    {
      key: 'third_party_re_attempt',
      label: 'Carrier Reattempt',
      show: masterData?.show_thirdparty_set_reattempt_option,
    },
    {
      key: 'third_party_reschedule',
      label: 'Carrier Reschedule',
      show: masterData?.show_thirdparty_set_reschedule_option,
    },
    {
      key: 'update_task_status_completed',
      label: 'Update Task Status Completed',
      show: masterData?.show_dashboard_consignment_update_task_success,
    },
    {
      key: 'update_task_status_failed',
      label: 'Update Task Status Failed',
      show: masterData?.show_dashboard_consignment_update_task_failed,
    },
    {
      key: 'update_consignment_destination_hub',
      label: 'Change Destination Hub',
      show: masterData?.ops_dashboard_config?.parts_to_show?.update_consignment_destination_hub,
    }
  ];
};

export const actionsForFirstRowHeader = ['mark_delivered', 'mark_undelivered', 'third_party_re_attempt', 'third_party_rto', 'update_consignment_destination_hub'];
export const renameKeySources = {
  mark_delivered_bulk_upload : 'mark_delivered',
  update_consignment_destination_hub_bulk_upload: 'update_consignment_destination_hub'
};

export const csvFileAcceptedActions = [
  'update_task_status_completed',
  'update_task_status_failed',
  'update_consignment_destination_hub'
];

export const updateConsignmentDestinationHubKeyMapping = {
  'Reference Number': 'reference_number',
  'Hub Code': 'hub_code',
  Notes: 'notes',
}

export const headerKeyMapping = {
  mark_delivered: {
    'Delivery Timestamp' : 'delivery_timestamp',
    Notes : 'notes',
    'Amount to be Collected' : 'cod_amount_collected',
    'Reference Number' : 'reference_number',
    'Mode of Payment': 'mode_of_payment',
    'Transaction ID': 'transaction_id',
  },
  mark_undelivered: {
    'Reference Number' : 'reference_number',
    'Attempted Timestamp' : 'attempted_timestamp',
    Notes : 'notes',
    'Failure Reason Id' : 'failure_reason_id',
  },
  third_party_re_attempt: {
    'Reference Number' : 'reference_number',
    'Courier Partner Reference Number': 'courier_partner_reference_number',
    'Courier Partner': 'courier_partner',
    Notes: 'cnNotes',
  },
  third_party_rto: {
    'Reference Number' : 'reference_number',
    'Courier Partner Reference Number': 'courier_partner_reference_number',
    'Courier Partner': 'courier_partner',
    Notes: 'cnNotes',
  },
  update_consignment_destination_hub: {
    ...updateConsignmentDestinationHubKeyMapping,
  },
};
