import * as React from 'react';
import withStyles from 'react-jss';
import { CssType,ThemeType} from '@theming/jssTypes';
import { connect } from 'react-redux';
import HeaderCard from '@components/common/DetailComponent/HeaderCard';
import TabSection from '@components/common/DetailComponent/TabSection';

const styles = (theme: ThemeType): CssType => ({
    tabsContainer: {
      margin: '10px 12px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      flex: 1,
    },
});

function ConsignmentDetailsContent(props: any) {
  const { classes, ops_consignment_details_view=null, cnData, reload, callLogsData } = props;
  return (
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      flex: 1,
      height: '100%',
      overflow: 'auto',
    }}>
      <HeaderCard
        columns={ops_consignment_details_view?.headers_to_show}
        data={cnData} />
      <div className={classes.tabsContainer} >
        <TabSection
          tabs={ops_consignment_details_view?.tabs_to_show}
          data={cnData}
          callLogsData={callLogsData}
          executionStatusList={props?.executionStatusList}
          reload={reload}
        />
      </div>
    </div>
  );
}
const mapStateToProps = ({ masterData }) => {
    return {
        masterData,
        executionStatusList: masterData?.consignment_execution_status_list,
        ops_consignment_details_view: masterData?.ops_consignment_details_view,
    };
};

const ConsignmentDetailsContentStyled = withStyles(styles,
  { injectTheme: true })(ConsignmentDetailsContent);
export default connect(mapStateToProps, {})(ConsignmentDetailsContentStyled);
